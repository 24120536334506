var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_c('div',{staticClass:"card-header d-flex justify-content-between"},[_vm._m(0),(_vm.validasi(['is_admin', 'jenis_transport-add']))?_c('span',{staticClass:"btn btn-primary",on:{"click":_vm.addBtnHandle}},[_vm._v(" Tambah Baru ")]):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 10,
              position: 'top',
              perPageDropdown: [5, 10, 20],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'Selanjutnya',
              prevLabel: 'Sebelumnya',
              rowsPerPageLabel: 'Jumlah baris',
              ofLabel: 'dari',
              pageLabel: 'halaman', // for 'pages' mode
              allLabel: 'Semua',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[(_vm.validasi(['is_admin', 'jenis_transport-edit']))?_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"primary","title":"Edit"},on:{"click":function($event){return _vm.editBtnHandle(props)}}},[_c('i',{staticClass:"dripicons dripicons-pencil"})]):_vm._e(),(_vm.validasi(['is_admin', 'jenis_transport-delete']))?_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"danger","title":"Hapus"},on:{"click":function($event){return _vm.showDelete(props)}}},[_c('i',{staticClass:"dripicons dripicons-trash"})]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('b-modal',{attrs:{"no-close-on-backdrop":true,"id":"modalDelete","title":"Hapus Kategori Pengeluaran"},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ cancel }){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Batal ")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.modalDelete()}}},[_vm._v(" Hapus ")])]}}])},[_c('p',[_vm._v("Apakah anda yakin untuk menghapus pendukung Data Jenis Transport: "),_c('strong',[_vm._v(_vm._s(_vm.labelPopup))]),_vm._v(" ?")])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("pendukung Data Jenis Transport")])])
}]

export { render, staticRenderFns }